import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
import {
  BROWSE_JOBS,
  BROWSE_JOBS_BY_LOCATION,
  JOB_POST,
  REVIEWS,
  TOTAL_JOBS_BY_LOCATION,
} from "../../apis/Constant/routes";
import { BLOGS_PER_PAGE } from "../../routes/constants";

export const getBrowseJobsByTechnologiesList = createAsyncThunk(
  "getBrowseJobsByTechnologiesList",
  async () => {
    return await AxiosApi({
      path: BROWSE_JOBS,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const getBrowseJobsByLocationsList = createAsyncThunk(
  "getBrowseJobsByLocationsList",
  async () => {
    return await AxiosApi({
      path: BROWSE_JOBS_BY_LOCATION,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const getTotalJobsByLocation = createAsyncThunk(
  "getTotalJobsByLocation",
  async (locationId) => {
    return await AxiosApi({
      path: `${TOTAL_JOBS_BY_LOCATION}=${locationId}`,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const getHrPolicies = createAsyncThunk(
  "getHrPolicies",
  async ({ pageNo = 1, recordsPerPage }) => {
    return await AxiosApi({
      path: `/hr_policies?page=${pageNo}&per_page=${recordsPerPage}`,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const getJobDescriptions = createAsyncThunk(
  "getJobDescriptions",
  async ({ pageNo = 1, recordsPerPage }) => {
    return await AxiosApi({
      path: `/job_description?page=${pageNo}&per_page=${recordsPerPage}`,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const getDescriptionSearch = createAsyncThunk(
  "getDescriptionSearch",
  async () => {
    return await AxiosApi({
      path: `${JOB_POST}`,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

// ----------- search jobs --------------
export const getAllCategories = createAsyncThunk(
  "getAllCategories",
  async () => {
    return await AxiosApi({
      path: "/categories",
      type: "GET",
      toAppendAuthParams: false,
    });
  }
);

export const getAllLocations = createAsyncThunk("getAllLocations", async () => {
  return await AxiosApi({
    path: "/locations",
    type: "GET",
    toAppendAuthParams: false,
  });
});

export const getAllSkills = createAsyncThunk("getAllSkills", async () => {
  return await AxiosApi({
    path: "/skills",
    type: "GET",
    toAppendAuthParams: false,
  });
});

export const getAllJobPosts = createAsyncThunk(
  "getAllJobPosts",
  async ({ jobTitle, filterUrl }) => {
    let url = "/job_posts?";

    if (jobTitle && jobTitle !== "undefined") {
      // url += `filter_by[job_title]=${jobTitle}&`;
      url += `search[is_active]=true&search[job]=${jobTitle}&`;
    }

    if (filterUrl && filterUrl !== "undefined") {
      url += `${filterUrl}`;
    }
    return await AxiosApi({
      path: url,
      type: "GET",
      toAppendAuthParams: false,
    });
  }
);

export const applyJobFromLandingPage = createAsyncThunk(
  "applyJobFromLandingPage",
  async ({ data }) => {
    return await AxiosApi({
      path: "/job_applications",
      type: "POST",
      data: data,
      toAppendAuthParams: false,
    });
  }
);

export const getBlogs = createAsyncThunk(
  "getBlogs",
  async ({ pageNo, blogsPerPage }) => {
    return await AxiosApi({
      path: `/blogs?page=${pageNo}&per_page=${blogsPerPage}`,
      type: "GET",
      toAppendAuthParams: false,
    });
  }
);

export const getAllBlogs = createAsyncThunk("getAllBlogs", async () => {
  return await AxiosApi({
    path: `/blogs`,
    type: "GET",
    toAppendAuthParams: false,
  });
});

export const getBlogDetail = createAsyncThunk("getBlogDetail", async (slug) => {
  return await AxiosApi({
    path: `/blogs/${slug}`,
    type: "GET",
    toAppendAuthParams: false,
  });
});

// Define initial state
const initialState = {
  searchTerm: 0,
  reviews: []
};

//reviews
export const getReviewed = createAsyncThunk(
  "reviewed",
  async () => {
    return await AxiosApi({
      path: REVIEWS,
      type: "GET",
      toAppendAuthParams: false,
    });
  }
);

// Define a slice
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchTerm(state, action) {
      state.search = action.payload;
    },
    setReviewed(state, action) {
      state.reviews = action.payload;
    },
  },
});

// Export actions and reducer
export default searchSlice.reducer;
export const { setSearchTerm, setReviewed } = searchSlice.actions;
