// users
export const USERS = "/users";

// login, signup
export const SIGNUP = `/signup`;
export const LOGIN = `/login`;

// forgot password
export const PASSWORD = `/password`;
export const FORGOT_PASSWORD = `/forgot_password`;
export const CHANGE_PASSWORD = `/change_password`;

// verify OTP
export const VERIFY_OTP = `/verify_otp`;

// reset password
export const RESET_PASSWORD = `/reset_password`;

// employer
export const EMPLOYER = "/employers";

// jobseeker
export const JOB_SEEKER = "/jobseekers";
export const JOB_SEEKER_DETAIL = "/jobseeker_details";
export const JOB_SEEKER_APPLICATION = "/job_applications";
export const NEW_APPLICANT_DESIGNATION = "/new_applicants_by_disignation";
export const DELETE_RESUME = "/delete_resume";
export const DOWNLOAD_RESUME = "/resume_download";

//Freelancer
export const FREELANCER = "/freelancer";
export const FREELANCER_DETAILS = "/freelancer_details";
export const FREELANCER_CANDIDATES = "/freelancer_candidates";
export const EARNING_CANDIDATE = "/earning_candidates";

//JobPost
export const JOB_POST = "/job_posts";
export const SKILLS = "/skills";
export const SAVED_JOBS = "/saved_jobs";
export const UNSAVED_JOBS = "/saved_jobs/delete_saved_job";

//Hiring Assistance
export const HIRING_ASSISTANCE = "/hiring_assistances";

// communication details
export const COMMUNICATION_DETAIL = `/communication_details`;

// employer details
export const EMPLOYER_DETAILS = "/employer_details";

// auths
export const AUTHS = "/auths";

// google auth
export const GOOGLE_AUTH_URL = "/google_auth_url";
export const GOOGLE_OMNI_AUTH = "/google_omniauth";

// facebook auth
export const FB_AUTH_URL = "/fb_auth_url";
export const FB_OMNI_AUTH = "/fb_omniauth";

// landing page
export const BROWSE_JOBS = "/browse_jobs";
export const BROWSE_JOBS_BY_LOCATION = "/locations";
export const TOTAL_JOBS_BY_LOCATION = "/job_posts?filter_by[location][]";

//reviews
export const REVIEWS = "/reviews";
