import openPosition from "./assets/images/open-postion.svg";
import applicant from "./assets/images/new-applicant.svg";
import shortlisted from "./assets/images/shortlisted.svg";
import hiringSuccess from "./assets/images/hiring-success.svg";

// NOTE* not to be removed, being used in employer dashboard stats cards
export const Jobs = [
  {
    id: 0,
    image: openPosition,
    number: "10",
    text: "Open Position",
  },
  {
    id: 1,
    image: applicant,
    number: "42",
    text: "New Applicants",
  },
  {
    id: 2,
    image: shortlisted,
    number: "24",
    text: "Shortlisted",
  },
  {
    id: 3,
    image: hiringSuccess,
    number: "75%",
    text: "Hired Success",
  },
];

export const tabData = [
  { title: "Earning Candidates", value: "earning_candidate" },
  { title: `Transaction History`, value: `transcation_history` },
];

export const allotedData = [
  { title: "All Jobs", value: "all_jobs" },
  { title: `Applied Jobs`, value: `applied_jobs` },
];

export const freelancerExperienceArr = [
  {
    label: "0-5 years",
    value: 0,
  },
  {
    label: "6-10 years",
    value: 1,
  },
  {
    label: "11-15 years",
    value: 2,
  },
  {
    label: "16-20 years",
    value: 3,
  },
];

export const experienceArr = [
  {
    label: "Fresher",
    value: 0,
  },
  {
    label: "6 months-1 year",
    value: 1
  },
  {
    label: "1 - 3 years",
    value: 2,
  },
  {
    label: "3 - 5 years",
    value: 3,
  },
  {
    label: "6-10 years",
    value: 4,
  },
  {
    label: "11-15 years",
    value: 5,
  },
  {
    label: "16-20 years",
    value: 6,
  },
];

export const ctcArr = [
  {
    label: "1-3 LPA",
    value: 0,
  },
  {
    label: "3-5 LPA",
    value: 1,
  },
  {
    label: "6-8 LPA",
    value: 2,
  },
  {
    label: "9-11 LPA",
    value: 3,
  },
  {
    label: "Not Disclosed",
    value: 4
  }
];

export const modeArr = [
  {
    label: "Remote",
    value: 0,
  },
  {
    label: "Office",
    value: 1,
  },
  {
    label: "Hybrid",
    value: 2,
  },
];

export const employmentStatusArr = [
  { label: "Fresher", value: "fresher", numericalValue: 0 },
  { label: "Employed", value: "employed", numericalValue: 1 },
  { label: "Self-Employed", value: "self_employed", numericalValue: 2 },
];
export const orgTypeArr = [
  {
    label: "Private Limited Company",
    value: "private_limited_company",
  },
  {
    label: "Public Limited Company",
    value: "public_limited_company",
  },
  {
    label: "Government Organization",
    value: "government_company",
  },
];

export const dummyText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

export const expertiesIn = [
  { id: 0, label: "Team Work", value: "Team Work" },
  { id: 1, label: "Problem Solving", value: "Problem Solving" },
  { id: 2, label: "Flexibility", value: "Flexibility" },
  { id: 3, label: "Change Management", value: "Change Management" },
  { id: 4, label: "Written Communication", value: "Written Communication" },
  { id: 5, label: "Active Listening", value: "Active Listening" },
  { id: 6, label: "Conflict Resolution", value: "Conflict Resolution" },
  { id: 7, label: "Relationship Building", value: "Relationship Building" },
  { id: 8, label: "Detail Orientation", value: "Detail Orientation" },
  { id: 9, label: "Learning Agility", value: "Learning Agility" },
  { id: 10, label: "Multitasking", value: "Multitasking" },
  { id: 11, label: "Time Management", value: "Time Management" },

];

export const ACTFilterDashboardMenuItems = [
  { label: "All Candidates", value: "all" },
  { label: "Viewed Candidates", value: "viewed" },
  { label: "Reviewed Candidates", value: "reviewed" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Sort A - Z", value: "asc" },
  { label: "Sort Z - A", value: "desc" },
];

export const ACTFilterDetailsMenuItems = [
  { label: "All Candidates", value: "all" },
  { label: "Viewed Candidates", value: "viewed" },
  { label: "Reviewed Candidates", value: "reviewed" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Sort A - Z", value: "asc" },
  { label: "Sort Z - A", value: "desc" },
];

export const SortMenuItems = [
  { label: "Sort A - Z", value: "asc" },
  { label: "Sort Z - A", value: "desc" },
];

export const jobPostArray = [
  { label: "Full Time", value: "Full Time" },
  { label: "Part Time", value: "Part Time" },
  { label: "Internship", value: "Internship" },
]

export const jobDatePostArray = [
  { label: "In Last 30 days", value: 30 },
  { label: "In Last 15 days", value: 15 },
  { label: "In Last 7 days", value: 7 },
  { label: "In Last 3 days", value: 3 },
  { label: "In Last 1 day", value: 1 },
]

export const workplaceArray = [
  { label: "Work from home", value: "Work from home" },
  { label: "Work from office", value: "Work from office" },
  { label: "Field job", value: "Field job" },
]

export const employementArray = [
  { label: "Full Time", value: "Full Time" },
  { label: "Part Time", value: "Part Time" },
]